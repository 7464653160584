<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Radicación de correo electrónico</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <!--
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-inbox v-on:search="searchItems" v-bind:item="search"></search-inbox>
                      -->
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Correo electrónico</td>
                      <td>Última fecha de consulta</td>
                      <td>Último UID</td>
                      <td>Último UID Validity</td>
                      <td>Filtro</td>
                      <td>Estado</td>
                      <td>Enviar notificación al radicar</td>
                      <td>Predeterminar</td>
                      <td>Permisos</td>
                      <!--
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                      -->
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                      </td>
                      <td>{{ item.inb_user }}</td>
                      <td>{{ item.inb_lastDate | formatDateUtc }}</td>
                      <td>{{ item.inb_lastUid }}</td>
                      <td>{{ item.inb_lastUidvalidity }}</td>
                      <td>{{ item.inb_filter }}</td>
                      <td>
                        <span v-if="item.openedInbox">Abierto</span>
                        <span v-else>Cerrado</span> 
                        <a href="#" v-on:click.prevent="showConfirmModal(index)" v-bind:disabled="item.disableButton" v-if="!item.disableButton">
                          <i v-if="!item.openedInbox" class="fas fa-play" style="font-size:20px;color:green" title="Abrir"></i>
                          <i v-else class="fas fa-stop" style="font-size:20px;color:red" title="Cerrar"></i>
                        </a>
                        <v-progress-circular v-if="item.disableButton" :size="20" :width="3" color="blue" indeterminate></v-progress-circular>
                      </td>
                      <td>{{ item.inb_sendNotification | formatBoolean }}</td>
                      <td>
                        <p v-if="!item.inb_default_company" class="text-center">
                          <a href="#" v-on:click.prevent="showDefaultModal(item)">Establecer como predeterminado</a>
                        </p>
                        <p v-else class="font-weight-bold text-primary text-center">
                          (Predeterminado)
                          <a href="#" v-on:click.prevent="showDefaultModalDelete(item)">
                            <i class="fas fa-trash" style="font-size:20px;color:red" title="Quitar predeterminado"></i>                            
                          </a>
                        </p>
                      </td>
                      <td><span v-for="(t, ti) in item.permisos"><span v-if="ti > 0">, </span>{{ t.usu_nmbre }}</span></td>
                      <!--
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                      -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <!--
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
            </div>
            <div class="modal-body">
              <div style="text-align:center"><img src="/images/other_mail.png"></div>
              <div style="text-align:center"><p>{{ confirmMsg }}</p></div>
              <div style="text-align:center"><p class="font-weight-bold text-primary">{{ confirmEmail }}</p></div>
              <div class="form-row">
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-primary" v-on:click.prevent="acceptButton">Aceptar</button>
                </div>
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelButton">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
    </div>
    <!-- End Modal -->       

    <!-- Modal -->
    <div class="modal" id="defaultModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
            </div>
            <div class="modal-body">
              <div style="text-align:center"><img src="/images/other_mail.png"></div>
              <div style="text-align:center"><p>¿Desea establecer esta cuenta de correo como predeterminada para la entidad?</p></div>
              <div style="text-align:center"><p class="font-weight-bold text-primary">{{ defaultEmailObject.inb_user }}</p></div>
              <div class="form-row">
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-primary" v-on:click.prevent="setDefaultEmail(defaultEmailObject._id)">Aceptar</button>
                </div>
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelDefaultEmail">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
    </div>
    <!-- End Modal -->   

    <!-- Modal -->
    <div class="modal" id="defaultModalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
            </div>
            <div class="modal-body">
              <div style="text-align:center"><p><img src="/images/other_mail.png"><i class="fas fa-trash" style="font-size:64px;color:red"></i></p></div>
              <div style="text-align:center"><p>¿Desea quitar la cuenta de correo predeterminada para la entidad?</p></div>
              <div style="text-align:center"><p class="font-weight-bold text-primary">{{ defaultEmailObject.inb_user }}</p></div>
              <div class="form-row">
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-primary" v-on:click.prevent="setDefaultEmailDelete(defaultEmailObject._id)">Aceptar</button>
                </div>
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelDefaultEmailDelete">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
    </div>
    <!-- End Modal -->            
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchInbox from "./SearchInbox.vue";
import { formatBoolean, formatDateUtc } from './../../plugins/filters';

export default {
  components: {
    //VIcon,
    SearchInbox
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      selected: null,
      confirmMsg: '',
      confirmEmail: '',
      defaultEmailObject: {},
      usuarios: []
    }
  },
  filters: {
    formatBoolean,
    formatDateUtc
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: async function(){
    this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
    var response = await this.axios.post('/usuarios/list', { cmp_id: String(this.$store.state.company) });    
    this.usuarios = response.data;
    console.log(JSON.stringify(this.usuarios));
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.searchItems();
    }
  },
  methods: {
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/inboxes/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems()
    {
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (var i = 0; i < this.delItems.length; i++){
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = '/inboxes/delete/' + this.delItems[i];
            this.axios.get(uri)
            .then(res => {
              //this.items.splice(index, 1);
              if (this.search) {
                this.searchItems(this.search);
              }
            })
            .catch(err => {
              console.log(err);
              for (var m = 0; m < this.items.length; m++){
                if (this.delItems[i] == this.items[m]._id){
                  alert('No se ha podido eliminar el item ' + this.items[m]);
                  m = this.items.length;
                }
              }
            });
          }
          if (this.filter){
            this.searchItems(this.search);
          } else {
            this.searchItems();
          }
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }
      
      this.message = 'Buscando buzones';
      p.cmp_id = this.$store.state.company;
      let uri = '/inboxes/search';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        console.log(response);
        var list = response.data;

        for(let i = 0; i < list.length; i++){
          list[i].permisos?.forEach(p => {
            var u = this.usuarios.find(e => e._id == p.usu_id);
            p.usu_nmbre = u.usu_nmbre + ' ' + u.usu_aplldo + ' (' + u.usu_cdgo + ')';
          });
          // Consultar estado del servidor de email
          let uri = '/siged-email/api/stateinbox';     
          this.axios.post(uri, { inb_id: list[i]._id, index: i })
          .then((response) => {
            console.log('Estado del Buzón');
            console.log(response);
            var state = response.data.state;
            var index = response.data.index;
            if (state == 'disconnected'){
              this.items[index].openedInbox = false;
              this.items[index].disableButton = false;            
            } else {
              this.items[index].openedInbox = true;
              this.items[index].disableButton = false;            
            }
            this.$set(this.items, index, this.items[index]);
          })
          .catch((err) => {
            console.log('Estado del Buzón');
            console.log(err);
            var index = err.response.data.index;
            this.items[index].openedInbox = false;
            this.items[index].disableButton = false;            
            this.$set(this.items, index, this.items[index]);
          });
        }

        this.items = list;
        this.search = p;
        this.delItems = [];
        this.filter = true;
      })
      .catch(err => {
        console.log(err);
        this.message = '¡Error al buscar los buzones' + err;
      });      
    }, 
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateInbox', params: { add: true, search: this.search } });
    },
    editItem(id){
      this.$router.push({ name: 'CreateInbox', params: { id: id, add: false, search: this.search } });
    },
    showDefaultModal(obj){
      this.defaultEmailObject = { inb_user: obj.inb_user, _id: obj._id };
      $('#defaultModal').modal('show');
    },
    setDefaultEmail(id){
      var p = {};
      let uri = '/inboxes/default-company';
      p.cmp_id = this.$store.state.company;
      p.id = id;
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        $('#defaultModal').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      })
      .catch(err => {
        $('#defaultModal').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      });
    },
    cancelDefaultEmail(){
      $('#defaultModal').modal('hide');
    },
    showDefaultModalDelete(obj){
      this.defaultEmailObject = { inb_user: obj.inb_user, _id: obj._id };
      $('#defaultModalDelete').modal('show');
    },
    setDefaultEmailDelete(id){
      var p = {};
      let uri = '/inboxes/default-company-delete';
      p.cmp_id = this.$store.state.company;
      p.id = id;
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        $('#defaultModalDelete').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      })
      .catch(err => {
        $('#defaultModalDelete').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      });
    },
    cancelDefaultEmailDelete(){
      $('#defaultModalDelete').modal('hide');
    },    
    showConfirmModal(i){
      if (!this.items[i].inb_lastDate){
        alert('No se ha definido la última fecha de consulta del correo electrónico seleccionado. Oprima el botón Editar y agregue los datos de la fecha y los valores predeterminados para la clasificación.');
        return;
      }
      this.selected = i;
      this.confirmEmail = this.items[i].inb_user;
      if (!this.items[i].openedInbox){
        this.confirmMsg = '¿Confirma que desea que radicar automáticamente TODOS los mensajes de este correo electrónico desde la fecha y el Id indicados en adelante.?';
      } else {
        this.confirmMsg = '¿Confirma que desea parar la radicación automática de los mensajes de este correo electrónico.?';
      }
      $('#confirmModal').modal('show');
    },
    acceptButton(){
      var i = this.selected;
      var p = {};
      p.cmp_id = this.$store.state.company;
      p.inb_id = this.items[i]._id;
      this.items[i].disableButton = true;
      this.$set(this.items, i, this.items[i]);
      $('#confirmModal').modal('hide');

      if (!this.items[i].openedInbox){
        let uri = '/siged-email/api/openinbox';     
        this.axios.post(uri, p)
        .then((response) => {
          console.log('Buzón abierto');
          this.items[i].openedInbox = true;
          this.items[i].disableButton = false;            
          this.$set(this.items, i, this.items[i]);
        })
        .catch((err) => {
          this.items[i].disableButton = false;            
          this.$set(this.items, i, this.items[i]);
          alert('Error al abrir el buzón de correo electrónico (' + this.items[i].inb_user + ').');
        });
      } else {
        let uri = '/siged-email/api/closeinbox';     
        this.axios.post(uri, p)
        .then((response) => {
          console.log('Buzón cerrado');
          this.items[i].openedInbox = false;
          this.items[i].disableButton = false;            
          this.$set(this.items, i, this.items[i]);
        })
        .catch((err) => {
          this.items[i].disableButton = false;            
          this.$set(this.items, i, this.items[i]);
          alert('Error al abrir el buzón de correo electrónico (' + this.items[i].inb_user + ').');
        });
      }      
    },
    cancelButton(){
      $('#confirmModal').modal('hide');
    }    
  }
}
</script>

<style>
.inactivo {
  /*
  background-color: #FFEAE8;
  */
  color: lightcoral;
  text-decoration: line-through;
}
</style>